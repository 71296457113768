import { history } from '../history';

export let BASE_URL = process.env.REACT_APP_BACKEND_API;
export let API_URL = BASE_URL + '/api';
export let RECEIPT_URL = BASE_URL + '/receipt';

export default async function _fetch(url, options = {}) {
	const token = localStorage.getItem('user');

	const { headers, ...requestOptions } = options;

	return fetch(`${API_URL}${url}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			authorization: `Bearer ${token}`,
			...headers,
		},
		...requestOptions,
	})
		.then(res => res.json())
		.then(res => {
			if (res.success) {
				return res;
			}
			return Promise.reject(res);
		})
		.catch(err => {
			console.log('Error: ', err);
			//toast.error(err.toString());
			if (typeof err.message === 'string' && err.message.includes('TokenExpiredError')) {
				console.warn('Here we go again Token Error');
				localStorage.clear();
				history.push('/login');
			} else {
				return Promise.reject(err);
			}
		});
}
