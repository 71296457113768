import { combineReducers } from 'redux';
import customizer from './customizer/';
import auth from './auth/';
import project from './project/';
import client from './client/';
import user from './user/';
import task from './task/';
import team from './team/';
import category from './category/';
import alert from './alert';
import timer from './timer/';
import expense from './expense/';
import reports from './reports/';
import navbar from './navbar/Index';

const rootReducer = combineReducers({
	customizer,
	auth,
	navbar,
	timer,
	project,
	client,
	task,
	user,
	team,
	category,
	alert,
	reports,
	expense,
});

export default rootReducer;
