import jwt from 'jsonwebtoken';
import { history } from '../../../history';

export const changeRole = role => {
	return dispatch => dispatch({ type: 'CHANGE_ROLE', userRole: role });
};

export const roles = {
	0: 'editor',
	1: 'admin',
	2: 'lead',
};

export const login = user => (dispatch, getState, http) => {
	return http('/auth/login', {
		method: 'POST',
		body: JSON.stringify(user),
	})
		.then(res => {
			localStorage.setItem('user', res.token);
			localStorage.setItem('refreshToken', res.refreshToken);
			const user = jwt.decode(res.token);
			const userRole = roles[+user.is_admin];
			user.is_admin = userRole;
			dispatch({
				type: 'LOGIN',
				user,
				userRole,
			});
		})
		.catch(err => err);
};
export const googleLogin = idToken => (dispatch, getState, http) => {
	return http('/auth/google', {
		method: 'POST',
		body: JSON.stringify({ idToken }),
	}).then(res => {
		localStorage.setItem('user', res.token);
		localStorage.setItem('refreshToken', res.refreshToken);
		const user = jwt.decode(res.token);
		const userRole = roles[+user.is_admin];
		user.is_admin = userRole;
		dispatch({
			type: 'LOGIN',
			user,
			userRole,
		});
		history.push('/');
	});
};
export const logout = () => dispatch => {
	localStorage.removeItem('user');
	localStorage.removeItem('refreshToken');
	dispatch({ type: 'USER_LOGOUT' });
	history.push('/login');
	// document.location.href = '/login'
};
