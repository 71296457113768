import React from 'react';
import * as Icon from 'react-feather';
const navigationConfig = [
	{
		type: 'groupHeader',
		groupTitle: 'MENU',
		permissions: ['admin', 'editor', 'lead'],
	},
	{
		id: 'time',
		title: 'Time',
		type: 'item',
		icon: <Icon.Calendar size={20} />,
		permissions: ['admin', 'editor', 'lead'],
		navLink: '/',
	},
	{
		id: 'reports',
		title: 'Reports',
		type: 'item',
		icon: <Icon.PieChart size={20} />,
		permissions: ['admin', 'editor', 'lead'],
		navLink: '/reports',
	},
	{
		id: 'expenses',
		title: 'Expenses',
		type: 'item',
		icon: <Icon.CreditCard size={20} />,
		permissions: ['admin', 'editor', 'lead'],
		navLink: '/expenses',
	},
	{
		id: 'profile',
		title: 'Profile',
		type: 'item',
		icon: <Icon.User size={20} />,
		permissions: ['admin', 'editor', 'lead'],
		navLink: '/profile',
	},
	{
		type: 'groupHeader',
		groupTitle: 'MANAGE',
		permissions: ['admin', 'lead'],
	},
	{
		id: 'clients',
		title: 'Clients',
		type: 'item',
		icon: <Icon.Briefcase size={20} />,
		permissions: ['admin'],
		navLink: '/clients',
	},
	{
		id: 'projects',
		title: 'Projects',
		type: 'item',
		icon: <Icon.Layout size={20} />,
		permissions: ['admin'],
		navLink: '/projects',
	},
	{
		id: 'task',
		title: 'Task',
		type: 'item',
		icon: <Icon.List size={20} />,
		permissions: ['admin'],
		navLink: '/tasks',
	},
	{
		id: 'users',
		title: 'Users',
		type: 'item',
		icon: <Icon.User size={20} />,
		permissions: ['admin'],
		navLink: '/users',
	},
	{
		id: 'teams',
		title: 'Teams',
		type: 'item',
		icon: <Icon.Users size={20} />,
		permissions: ['admin'],
		navLink: '/teams',
	},
	{
		id: 'categories',
		title: 'Categories',
		type: 'item',
		icon: <Icon.Book size={20} />,
		permissions: ['admin'],
		navLink: '/categories',
	},
	{
		id: 'workload',
		title: 'Workload',
		type: 'item',
		icon: <Icon.BarChart2 size={20} />,
		permissions: ['admin'],
		navLink: '/workload',
	},
	{
		id: 'adminReport',
		title: 'Reports',
		type: 'external-link',
		icon: <Icon.PieChart size={20} />,
		permissions: ['admin', 'lead'],
		navLink: 'https://time.hype.com.tr/admin/reports',
	},
];

export default navigationConfig;
