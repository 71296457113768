const initialState = {
	clients: [],
	loading: false,
	modalLoading: false,
	client: { name: '', id: '' },
};

const clientReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_LOADING':
			return {
				...state,
				loading: action.payload,
			};
		case 'SET_MODAL_LOADING':
			return {
				...state,
				modalLoading: action.payload,
			};
		case 'INIT_DATA':
			return {
				...state,
				clients: action.clients,
				loading: false,
			};
		case 'SET_CLIENTS':
			return {
				...state,
				clients: action.clients,
			};
		case 'NEW_CLIENT':
			return {
				...state,
				client: { name: '', id: '' },
				clients: [action.client, ...state.clients],
			};
		case 'SET_CLIENT':
			return {
				...state,
				client: action.payload,
			};
		case 'SET_CLIENT_NAME':
			return {
				...state,
				client: { ...state.client, name: action.payload },
			};
		case 'RESET_CLIENT':
			return {
				...state,
				client: { name: '', id: '' },
			};
		default:
			return state;
	}
};

export default clientReducer;
