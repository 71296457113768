const initialState = {
	users: [],
	projects: [],
	loading: false,
	modalLoading: false,
	user: {
		name: '',
		email: '',
		Projects: [],
	},
};
const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_LOADING':
			return {
				...state,
				loading: action.payload,
			};
		case 'SET_MODAL_LOADING':
			return {
				...state,
				modalLoading: action.payload,
			};
		case 'INIT_DATA':
			return {
				...state,
				users: action.users,
				projects: action.projects,
				loading: false,
			};
		case 'SET_USERS':
			return {
				...state,
				users: action.users,
			};
		case 'NEW_USER':
			return {
				...state,
				user: {
					name: '',
					email: '',
					Projects: [],
				},
				users: [action.user, ...state.users],
			};
		case 'RESET_USER':
			return {
				...state,
				user: {
					name: '',
					email: '',
					Projects: [],
				},
			};
		case 'SET_USER':
			return {
				...state,
				user: action.payload,
			};
		default:
			return state;
	}
};

export default userReducer;
